<template>
  <div class="page_search">
    <div class="warp">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="card_result_search">
              <div class="title">搜索结果</div>

              <!-- 文章搜索结果 -->
              <list_result_search
                  :list="result_article"
                  title="品牌资讯"
                  source_table="article"
              ></list_result_search>


              <list_result_search
                  v-if="$check_action('/ordinary_users/list', 'get')"
                  :list="result_ordinary_users_user_name"
                  title="普通用户用户姓名"
                  source_table="ordinary_users"
              ></list_result_search>
              <list_result_search
                  v-if="$check_action('/ordinary_users/list', 'get')"
                  :list="result_ordinary_users_gender"
                  title="普通用户性别"
                  source_table="ordinary_users"
              ></list_result_search>
              <list_result_search
                  v-if="$check_action('/merchant_users/list', 'get')"
                  :list="result_merchant_users_merchant_name"
                  title="商家用户商家名称"
                  source_table="merchant_users"
              ></list_result_search>
              <list_result_search
                  v-if="$check_action('/room_type/list', 'get')"
                  :list="result_room_type_room_type"
                  title="产品类型产品类型"
                  source_table="room_type"
              ></list_result_search>
              <list_result_search
                  v-if="$check_action('/home_stay_information/list', 'get')"
                  :list="result_home_stay_information_name_of_home_stay"
                  title="产品信息产品名称"
                  source_table="home_stay_information"
              ></list_result_search>
              <list_result_search
                  v-if="$check_action('/home_stay_information/list', 'get')"
                  :list="result_home_stay_information_room_type"
                  title="产品信息产品类型"
                  source_table="home_stay_information"
              ></list_result_search>
<!--              <list_result_search-->
<!--                  v-if="$check_action('/home_stay_information/list', 'get')"-->
<!--                  :list="result_home_stay_information_merchant_name"-->
<!--                  title="产品信息商家名称"-->
<!--                  source_table="home_stay_information"-->
<!--              ></list_result_search>-->
<!--              <list_result_search-->
<!--                  v-if="$check_action('/reservation_information/list', 'get')"-->
<!--                  :list="result_reservation_information_name_of_home_stay"-->
<!--                  title="预定信息产品名称"-->
<!--                  source_table="reservation_information"-->
<!--              ></list_result_search>-->
<!--              <list_result_search-->
<!--                  v-if="$check_action('/reservation_information/list', 'get')"-->
<!--                  :list="result_reservation_information_room_type"-->
<!--                  title="预定信息产品类型"-->
<!--                  source_table="reservation_information"-->
<!--              ></list_result_search>-->
<!--              <list_result_search-->
<!--                  v-if="$check_action('/reservation_information/list', 'get')"-->
<!--                  :list="result_reservation_information_merchant_name"-->
<!--                  title="预定信息产品名称"-->
<!--                  source_table="reservation_information"-->
<!--              ></list_result_search>-->
<!--              <list_result_search-->
<!--                  v-if="$check_action('/apply_for_check_out/list', 'get')"-->
<!--                  :list="result_apply_for_check_out_name_of_home_stay"-->
<!--                  title="申请退订产品名称"-->
<!--                  source_table="apply_for_check_out"-->
<!--              ></list_result_search>-->
<!--              <list_result_search-->
<!--                  v-if="$check_action('/apply_for_check_out/list', 'get')"-->
<!--                  :list="result_apply_for_check_out_room_type"-->
<!--                  title="申请退订产品类型"-->
<!--                  source_table="apply_for_check_out"-->
<!--              ></list_result_search>-->
<!--              <list_result_search-->
<!--                  v-if="$check_action('/apply_for_check_out/list', 'get')"-->
<!--                  :list="result_apply_for_check_out_merchant_name"-->
<!--                  title="申请退订名称"-->
<!--                  source_table="apply_for_check_out"-->
<!--              ></list_result_search>-->
<!--              <list_result_search-->
<!--                  v-if="$check_action('/check_out_information/list', 'get')"-->
<!--                  :list="result_check_out_information_name_of_home_stay"-->
<!--                  title="退订产品信息产品名称"-->
<!--                  source_table="check_out_information"-->
<!--              ></list_result_search>-->
<!--              <list_result_search-->
<!--                  v-if="$check_action('/check_out_information/list', 'get')"-->
<!--                  :list="result_check_out_information_room_type"-->
<!--                  title="退房信息房间类型"-->
<!--                  source_table="check_out_information"-->
<!--              ></list_result_search>-->
<!--              <list_result_search-->
<!--                  v-if="$check_action('/check_out_information/list', 'get')"-->
<!--                  :list="result_check_out_information_merchant_name"-->
<!--                  title="退房信息商家名称"-->
<!--                  source_table="check_out_information"-->
<!--              ></list_result_search>-->
<!--              <list_result_search-->
<!--                  v-if="$check_action('/consultation_information/list', 'get')"-->
<!--                  :list="result_consultation_information_name_of_home_stay"-->
<!--                  title="咨询信息民宿名称"-->
<!--                  source_table="consultation_information"-->
<!--              ></list_result_search>-->
<!--              <list_result_search-->
<!--                  v-if="$check_action('/consultation_information/list', 'get')"-->
<!--                  :list="result_consultation_information_room_type"-->
<!--                  title="咨询信息房间类型"-->
<!--                  source_table="consultation_information"-->
<!--              ></list_result_search>-->
<!--              <list_result_search-->
<!--                  v-if="$check_action('/consultation_information/list', 'get')"-->
<!--                  :list="result_consultation_information_merchant_name"-->
<!--                  title="咨询信息商家名称"-->
<!--                  source_table="consultation_information"-->
<!--              ></list_result_search>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../mixins/page.js";
import list_result_search from "../../components/diy/list_result_search.vue";

export default {
  mixins: [mixin],
  data() {
    return {
      "query": {
        word: "",
      },
      "result_article": [],
      "result_ordinary_users_user_name": [],
      "result_ordinary_users_gender": [],
      "result_merchant_users_merchant_name": [],
      "result_room_type_room_type": [],
      "result_home_stay_information_name_of_home_stay": [],
      "result_home_stay_information_room_type": [],
      "result_home_stay_information_merchant_name": [],
      "result_reservation_information_name_of_home_stay": [],
      "result_reservation_information_room_type": [],
      "result_reservation_information_merchant_name": [],
      "result_apply_for_check_out_name_of_home_stay": [],
      "result_apply_for_check_out_room_type": [],
      "result_apply_for_check_out_merchant_name": [],
      "result_check_out_information_name_of_home_stay": [],
      "result_check_out_information_room_type": [],
      "result_check_out_information_merchant_name": [],
      "result_consultation_information_name_of_home_stay": [],
      "result_consultation_information_room_type": [],
      "result_consultation_information_merchant_name": [],
    };
  },
  methods: {
    /**
     * 获取文章
     */
    get_article() {
      this.$get("~/api/article/get_list?like=0", {page: 1, size: 10, title: this.query.word}, (json) => {
        if (json.result) {
          this.result_article = json.result.list;
        }
      });
    },

    /**
     * 获取user_name
     */
    get_ordinary_users_user_name() {
      this.$get("~/api/ordinary_users/get_list?like=0", {page: 1, size: 10, "user_name": this.query.word}, (json) => {
        if (json.result) {
          var result_ordinary_users_user_name = json.result.list;
          result_ordinary_users_user_name.map(o => o.title = o['user_name'])
          this.result_ordinary_users_user_name = result_ordinary_users_user_name
        }
      });
    },
    /**
     * 获取gender
     */
    get_ordinary_users_gender() {
      this.$get("~/api/ordinary_users/get_list?like=0", {page: 1, size: 10, "gender": this.query.word}, (json) => {
        if (json.result) {
          var result_ordinary_users_gender = json.result.list;
          result_ordinary_users_gender.map(o => o.title = o['gender'])
          this.result_ordinary_users_gender = result_ordinary_users_gender
        }
      });
    },
    /**
     * 获取merchant_name
     */
    get_merchant_users_merchant_name() {
      this.$get("~/api/merchant_users/get_list?like=0", {
        page: 1,
        size: 10,
        "merchant_name": this.query.word
      }, (json) => {
        if (json.result) {
          var result_merchant_users_merchant_name = json.result.list;
          result_merchant_users_merchant_name.map(o => o.title = o['merchant_name'])
          this.result_merchant_users_merchant_name = result_merchant_users_merchant_name
        }
      });
    },
    /**
     * 获取room_type
     */
    get_room_type_room_type() {
      this.$get("~/api/room_type/get_list?like=0", {page: 1, size: 10, "room_type": this.query.word}, (json) => {
        if (json.result) {
          var result_room_type_room_type = json.result.list;
          result_room_type_room_type.map(o => o.title = o['room_type'])
          this.result_room_type_room_type = result_room_type_room_type
        }
      });
    },
    /**
     * 获取name_of_home_stay
     */
    get_home_stay_information_name_of_home_stay() {
      this.$get("~/api/home_stay_information/get_list?like=0", {
        page: 1,
        size: 10,
        "name_of_home_stay": this.query.word
      }, (json) => {
        if (json.result) {
          var result_home_stay_information_name_of_home_stay = json.result.list;
          result_home_stay_information_name_of_home_stay.map(o => o.title = o['name_of_home_stay'])
          this.result_home_stay_information_name_of_home_stay = result_home_stay_information_name_of_home_stay
        }
      });
    },
    /**
     * 获取room_type
     */
    get_home_stay_information_room_type() {
      this.$get("~/api/home_stay_information/get_list?like=0", {
        page: 1,
        size: 10,
        "room_type": this.query.word
      }, (json) => {
        if (json.result) {
          var result_home_stay_information_room_type = json.result.list;
          result_home_stay_information_room_type.map(o => o.title = o['room_type'])
          this.result_home_stay_information_room_type = result_home_stay_information_room_type
        }
      });
    },
    /**
     * 获取merchant_name
     */
    get_home_stay_information_merchant_name() {
      this.$get("~/api/home_stay_information/get_list?like=0", {
        page: 1,
        size: 10,
        "merchant_name": this.query.word
      }, (json) => {
        if (json.result) {
          var result_home_stay_information_merchant_name = json.result.list;
          result_home_stay_information_merchant_name.map(o => o.title = o['merchant_name'])
          this.result_home_stay_information_merchant_name = result_home_stay_information_merchant_name
        }
      });
    },
    /**
     * 获取name_of_home_stay
     */
    get_reservation_information_name_of_home_stay() {
      this.$get("~/api/reservation_information/get_list?like=0", {
        page: 1,
        size: 10,
        "name_of_home_stay": this.query.word
      }, (json) => {
        if (json.result) {
          var result_reservation_information_name_of_home_stay = json.result.list;
          result_reservation_information_name_of_home_stay.map(o => o.title = o['name_of_home_stay'])
          this.result_reservation_information_name_of_home_stay = result_reservation_information_name_of_home_stay
        }
      });
    },
    /**
     * 获取room_type
     */
    get_reservation_information_room_type() {
      this.$get("~/api/reservation_information/get_list?like=0", {
        page: 1,
        size: 10,
        "room_type": this.query.word
      }, (json) => {
        if (json.result) {
          var result_reservation_information_room_type = json.result.list;
          result_reservation_information_room_type.map(o => o.title = o['room_type'])
          this.result_reservation_information_room_type = result_reservation_information_room_type
        }
      });
    },
    /**
     * 获取merchant_name
     */
    get_reservation_information_merchant_name() {
      this.$get("~/api/reservation_information/get_list?like=0", {
        page: 1,
        size: 10,
        "merchant_name": this.query.word
      }, (json) => {
        if (json.result) {
          var result_reservation_information_merchant_name = json.result.list;
          result_reservation_information_merchant_name.map(o => o.title = o['merchant_name'])
          this.result_reservation_information_merchant_name = result_reservation_information_merchant_name
        }
      });
    },
    /**
     * 获取name_of_home_stay
     */
    get_apply_for_check_out_name_of_home_stay() {
      this.$get("~/api/apply_for_check_out/get_list?like=0", {
        page: 1,
        size: 10,
        "name_of_home_stay": this.query.word
      }, (json) => {
        if (json.result) {
          var result_apply_for_check_out_name_of_home_stay = json.result.list;
          result_apply_for_check_out_name_of_home_stay.map(o => o.title = o['name_of_home_stay'])
          this.result_apply_for_check_out_name_of_home_stay = result_apply_for_check_out_name_of_home_stay
        }
      });
    },
    /**
     * 获取room_type
     */
    get_apply_for_check_out_room_type() {
      this.$get("~/api/apply_for_check_out/get_list?like=0", {
        page: 1,
        size: 10,
        "room_type": this.query.word
      }, (json) => {
        if (json.result) {
          var result_apply_for_check_out_room_type = json.result.list;
          result_apply_for_check_out_room_type.map(o => o.title = o['room_type'])
          this.result_apply_for_check_out_room_type = result_apply_for_check_out_room_type
        }
      });
    },
    /**
     * 获取merchant_name
     */
    get_apply_for_check_out_merchant_name() {
      this.$get("~/api/apply_for_check_out/get_list?like=0", {
        page: 1,
        size: 10,
        "merchant_name": this.query.word
      }, (json) => {
        if (json.result) {
          var result_apply_for_check_out_merchant_name = json.result.list;
          result_apply_for_check_out_merchant_name.map(o => o.title = o['merchant_name'])
          this.result_apply_for_check_out_merchant_name = result_apply_for_check_out_merchant_name
        }
      });
    },
    /**
     * 获取name_of_home_stay
     */
    get_check_out_information_name_of_home_stay() {
      this.$get("~/api/check_out_information/get_list?like=0", {
        page: 1,
        size: 10,
        "name_of_home_stay": this.query.word
      }, (json) => {
        if (json.result) {
          var result_check_out_information_name_of_home_stay = json.result.list;
          result_check_out_information_name_of_home_stay.map(o => o.title = o['name_of_home_stay'])
          this.result_check_out_information_name_of_home_stay = result_check_out_information_name_of_home_stay
        }
      });
    },
    /**
     * 获取room_type
     */
    get_check_out_information_room_type() {
      this.$get("~/api/check_out_information/get_list?like=0", {
        page: 1,
        size: 10,
        "room_type": this.query.word
      }, (json) => {
        if (json.result) {
          var result_check_out_information_room_type = json.result.list;
          result_check_out_information_room_type.map(o => o.title = o['room_type'])
          this.result_check_out_information_room_type = result_check_out_information_room_type
        }
      });
    },
    /**
     * 获取merchant_name
     */
    get_check_out_information_merchant_name() {
      this.$get("~/api/check_out_information/get_list?like=0", {
        page: 1,
        size: 10,
        "merchant_name": this.query.word
      }, (json) => {
        if (json.result) {
          var result_check_out_information_merchant_name = json.result.list;
          result_check_out_information_merchant_name.map(o => o.title = o['merchant_name'])
          this.result_check_out_information_merchant_name = result_check_out_information_merchant_name
        }
      });
    },
    /**
     * 获取name_of_home_stay
     */
    get_consultation_information_name_of_home_stay() {
      this.$get("~/api/consultation_information/get_list?like=0", {
        page: 1,
        size: 10,
        "name_of_home_stay": this.query.word
      }, (json) => {
        if (json.result) {
          var result_consultation_information_name_of_home_stay = json.result.list;
          result_consultation_information_name_of_home_stay.map(o => o.title = o['name_of_home_stay'])
          this.result_consultation_information_name_of_home_stay = result_consultation_information_name_of_home_stay
        }
      });
    },
    /**
     * 获取room_type
     */
    get_consultation_information_room_type() {
      this.$get("~/api/consultation_information/get_list?like=0", {
        page: 1,
        size: 10,
        "room_type": this.query.word
      }, (json) => {
        if (json.result) {
          var result_consultation_information_room_type = json.result.list;
          result_consultation_information_room_type.map(o => o.title = o['room_type'])
          this.result_consultation_information_room_type = result_consultation_information_room_type
        }
      });
    },
    /**
     * 获取merchant_name
     */
    get_consultation_information_merchant_name() {
      this.$get("~/api/consultation_information/get_list?like=0", {
        page: 1,
        size: 10,
        "merchant_name": this.query.word
      }, (json) => {
        if (json.result) {
          var result_consultation_information_merchant_name = json.result.list;
          result_consultation_information_merchant_name.map(o => o.title = o['merchant_name'])
          this.result_consultation_information_merchant_name = result_consultation_information_merchant_name
        }
      });
    },

  },
  components: {list_result_search},
  created() {
    this.query.word = this.$route.query.word || "";
  },
  mounted() {
    this.get_article();
    this.get_ordinary_users_user_name();
    this.get_ordinary_users_gender();
    this.get_merchant_users_merchant_name();
    this.get_room_type_room_type();
    this.get_home_stay_information_name_of_home_stay();
    this.get_home_stay_information_room_type();
    this.get_home_stay_information_merchant_name();
    this.get_reservation_information_name_of_home_stay();
    this.get_reservation_information_room_type();
    this.get_reservation_information_merchant_name();
    this.get_apply_for_check_out_name_of_home_stay();
    this.get_apply_for_check_out_room_type();
    this.get_apply_for_check_out_merchant_name();
    this.get_check_out_information_name_of_home_stay();
    this.get_check_out_information_room_type();
    this.get_check_out_information_merchant_name();
    this.get_consultation_information_name_of_home_stay();
    this.get_consultation_information_room_type();
    this.get_consultation_information_merchant_name();
  },
  watch: {
    $route() {
      $.push(this.query, this.$route.query);
      this.get_article();
      this.get_ordinary_users_user_name();
      this.get_ordinary_users_gender();
      this.get_merchant_users_merchant_name();
      this.get_room_type_room_type();
      this.get_home_stay_information_name_of_home_stay();
      this.get_home_stay_information_room_type();
      this.get_home_stay_information_merchant_name();
      this.get_reservation_information_name_of_home_stay();
      this.get_reservation_information_room_type();
      this.get_reservation_information_merchant_name();
      this.get_apply_for_check_out_name_of_home_stay();
      this.get_apply_for_check_out_room_type();
      this.get_apply_for_check_out_merchant_name();
      this.get_check_out_information_name_of_home_stay();
      this.get_check_out_information_room_type();
      this.get_check_out_information_merchant_name();
      this.get_consultation_information_name_of_home_stay();
      this.get_consultation_information_room_type();
      this.get_consultation_information_merchant_name();
    },
  },
};
</script>

<style scoped>
.card_search {
  text-align: center;
}

.card_result_search > .title {
  text-align: center;
  padding: 10px 0;
}
</style>
